@import '~@which/seatbelt/src/styles/styles';

.policyAndInsightArticle {
  &Header>div:not(:first-child):not(:last-child) {
    padding-top: $sb-spacing-s;
  }

  &ShowMore {
    button {
      display: block;
      margin: auto;
    }
  }

  &Wrapper {
    padding-bottom: $sb-spacing-3xl;
  }

  &SharingOptions {
    @include sb-respond-to(medium-up) {
      position: relative;
      top: 53px;
    }
  }

  &Related,
  &Downloads {
    @include sb-respond-to(large) {
      position: relative;
    }
  }
}

section[class^='GridItemArticle_share'] {
  @include sb-respond-to(medium-up) {
    position: sticky;
    top: 32px;
    max-height: calc(100vh - 10px);
  }
}

aside[class^='sidebar'] {
  @include sb-respond-to(large) {
    position: sticky;
    top: 32px;
    margin-bottom: $sb-spacing-l;
    padding-top: 60px;
  }
}
