@import '@which/seatbelt/src/styles/styles';

.zoomImageModal {
  .slideWrapper {
    width: 100%;
    padding-top: 50%;
    position: relative;
    background-color: sb-color('sb-color-black-05');
    margin: $sb-spacing-l 0;

    .imageWrapper {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  [data-testid='modalContainer'] {
    z-index: 1003;

    [data-testid='modalContent'] {
      width: 1123px;
      max-height: none;

      [class^='Carousel__imageMagnifyRoot'] {
        transform: none;
        top: auto;
      }

      //SB-TODO - double underscore > single underscore
      [class^='Carousel_imageMagnifyRoot'] {
        transform: none;
        top: auto;
      }

      [data-testid='closeButton'] {
        svg {
          position: relative;
          right: -1px;
        }
      }
    }
  }
}

.bodyImageWrapper {
  position: relative;

  [class^='ZoomImageHint__hint'] {
    bottom: 37px;
    right: 12px;
    pointer-events: none;
    z-index: 1;
  }

  //SB-TODO - double underscore > single underscore
  [class^='ZoomImageHint_hint'] {
    bottom: 37px;
    right: 12px;
    pointer-events: none;
    z-index: 1;
  }

  .bodyImageButton {
    position: absolute;
    left: 0;
    top: $sb-spacing-l;
    width: 100%;
    bottom: $sb-spacing-l;
    z-index: 1;
    padding: 0;
    cursor: zoom-in;
  }
}
