@import '~@which/seatbelt/src/styles/styles';

.usedCardLegalAdvice {
  margin-bottom: $sb-spacing-3xl;
}

.carToolIntro {
  padding: 24px 0 18px;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 44px 0 50px;
  }

  @include sb-respond-to(large) {
    margin-top: 0;
    padding: 58px 0 50px;
  }
}
