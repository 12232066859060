@import '~@which/seatbelt/src/styles/styles';

.basicHeader {
  @include sb-respond-to(medium-up) {
    margin: $sb-spacing-3xl auto;
  }

  text-align: center;
  margin: $sb-spacing-l auto;

  &Title {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-m;
    }
    margin-bottom: $sb-spacing-m;
  }

  &Standfirst {
    @include sb-respond-to(medium-up) {
      margin-bottom: $sb-spacing-l;
    }
    margin-bottom: $sb-spacing-l;
  }
}
