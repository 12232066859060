@import '~@which/seatbelt/src/styles/styles';

.scamsArticle {
  margin-top: $sb-spacing-3xl;

  h2:not([class^='sb'], [class^='realLifeStoryHeading']),
  h3:not([class^='sb']) {
    text-align: center;
    justify-content: center;
  }

  ul {
    margin: 0 10.4%;

    li:nth-child(n + 2) {
      &::before {
        background-color: transparent;
      }
    }
  }

  section {
    //overriding standard h2 style to allow for center alignment as its original display prop is flex
    h2 {
      display: block;
    }

    figure {
      display: flex;
      margin: 0;

      @include sb-respond-to(large) {
        margin-right: 91px;
      }
    }
  }
}
